.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;

}
.modal-dialog-centered {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-content {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 3px;
  outline: 0;
}
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
}
.card-footer:last-child {
  border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
}
input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.input-icon {
  position: relative;
}
.input-icon-addon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  color: #9aa0ac;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 2.5rem;
  pointer-events: none;
}
.form-control {
  display: block;
  width: 100%;
  height: 2.375rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,40,100,0.12);
  border-radius: 3px;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: #cd201f;
}
.fa-chalkboard-teacher:before {
  content: "\f51c";
}
.allow-icon-click {
  pointer-events: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.fa-dice:before {
  content: "\f522";
}
.input-icon .form-control:not(:last-child) {
  padding-right: 2.5rem;
}
.input-icon .form-control:not(:first-child) {
  padding-left: 2.5rem;
}.input-icon-addon:last-child {
  left: auto;
  right: 0;
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.custom-switch-description {
  margin-left: .5rem;
  color: #6e7687;
  -webkit-transition: .3s color;
  transition: .3s color;
}
.custom-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
.custom-switch-indicator {
  display: inline-block;
  height: 1.25rem;
  width: 2.25rem;
  background: #e9ecef;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid rgba(0,40,100,0.12);
  -webkit-transition: .3s border-color, .3s background-color;
  transition: .3s border-color, .3s background-color;
}
.custom-switch-indicator:before {
  content: '';
  position: absolute;
  height: calc(1.25rem - 4px);
  width: calc(1.25rem - 4px);
  top: 1px;
  left: 1px;
  background: #fff;
  border-radius: 50%;
  -webkit-transition: .3s left;
  transition: .3s left;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.4);
}
 .show{
  padding-right: 6px;
  display: block;
 }
.close-icon{
  position: absolute;
    width: 32px;
    height: 32px;
    font-size: 25px;
    top: 5px;
    right: 5px;
    z-index: 1;
    cursor: pointer;
    pointer-events: auto;
}


.custom-switch-input:checked ~ .custom-switch-indicator {
  @include colorify($colors) {
    background-color: colored('backgroundColor')!important;
    border-color: colored('borderColor') !important;
  }

  
}
.custom-switch-input:checked ~ .custom-switch-indicator {
  background: #467fcf;
}


.custom-switch-input:checked ~ .custom-switch-indicator:before {
  left: calc(1rem + 1px);
}

.room-card{
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}
.room-card-container{
  cursor: pointer;
  border:2px solid rgba(128,128,128,0.25);
  &:hover, &.selected{
    @include colorify($colors) {
      box-shadow:colored('boxShadowColor') 2px 2px 10px 2px;
    }
    
  }
  .icon{
    font-size:22px;
  }
  .icon:hover{
    transform:scale(1.2)
  }
  .icon-add{
    font-size:30px;
  }

}