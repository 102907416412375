.box{
    transition: opacity 1s linear, margin-top 0.5s step-start, z-index 0.5s step-start;
    display: block;

    &.hidden{
        opacity: 0;
        position: absolute;
        z-index: -5;
        transition-timing-function: linear, step-end, step-end;
    }
    
}

.react-sweet-progress-symbol{
    font-size: 21px;
}

.box .react-sweet-progress-symbol{
    font-size: 18px;
}

.MuiStepIcon-active,.MuiStepIcon-completed {
    @include colorify($colors) {
        color: colored('textColor')!important;
      }
}

.room-icon{
    @include colorify($colors) {
        color: colored('textColor');
      }
      &.red{
          color:#ff3a31
      }
}

.break{
    flex-basis: 100%;
    width:0;
    display: none;
}

.btn-after-break{
    margin: 0px 0px 0px auto;
}

@media screen and (max-width:500px){
    .break{
        display: flex;
    }
    .btn-after-break{
        margin: 0;
        margin-top: 10px;
    }
}